<template>
<div class="s-layout" :style="colorObject">
  <div class="s-container">
    <Header
      :meta="meta"
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
    />

    <div class="page">
      <div class="p-3 liff-reservation-form">
        <b-card header-tag="header" class="mb-3 liff-reservation-form__card">
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0">預約人資訊</h6>
              <button class="btn btn-link btn-sm">儲存</button>
            </div>
          </template>
          <b-form-group label="姓名" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
            <b-form-input v-model="form.name" class="text-right" />
          </b-form-group>
          <b-form-group label="電話" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm">
            <b-form-input v-model="form.phone" class="text-right" />
          </b-form-group>
        </b-card>

        <b-card header-tag="header" class="mb-3 liff-reservation-form__card">
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0">預約資訊</h6>
              <button class="btn btn-link btn-sm">儲存</button>
            </div>
          </template>
          <b-form-group label="項目名稱" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
            <b-form-input v-model="form.serviceName" class="text-right" />
          </b-form-group>
          <b-form-group label="服務人員" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
            <b-form-input v-model="form.servicePerson" class="text-right" />
          </b-form-group>
          <b-form-group label="預約時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
            <b-form-input v-model="form.serviceTime" class="text-right" />
          </b-form-group>
          <b-form-group label="服務地點" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
            <b-form-input v-model="form.serviceLocation" class="text-right" />
          </b-form-group>
        </b-card>

        <div class="mb-3">
          <b-form-group label="備註">
            <b-form-textarea v-model="form.note" class="liff-reservation-form__textarea" />
          </b-form-group>
        </div>

        <div class="d-flex s-space-x-2">
          <SharedButton class="s-btn-outline-primary w-50">上一步</SharedButton>
          <SharedButton class="s-btn-bg-primary w-50">送出</SharedButton>
        </div>
      </div>
    </div>
	</div>
</div>
</template>

<script>
import SharedButton from '@/components/Page/Liff/Shared/Button.vue';
import Header from '@/components/Page/Liff/Shared/Header'
import bookingMixin from "@/mixins/liff/booking";
import { themeColorFn } from "@/mixins/liff/themeColor";
// import themeColor from "@/mixins/liff/themeColor";
import { mapGetters } from 'vuex';

export default {
	mixins: [
    bookingMixin,
    // themeColor,
    themeColorFn({ themeConfigPage: 'reservation' })
  ],
	components: {
    SharedButton,
		Header,
	},
	computed: {
		...mapGetters({
			meta: "liffGeneral/meta",
      themeConfig: 'liffGeneral/themeConfig',
      getModule: 'liffModule/getModule',
    }),
	},
  data() {
    return {
      themeConfigPage: "reservation",
      form: {
        name: '林曉娟',
        phone: '0987654321',
        serviceName: '精緻剪髮、歐系染髮、洗髮',
        servicePerson: 'Nymphia',
        serviceTime: '2024/01/01 09:00',
        serviceLocation: '台北市中山區中山北路二段36巷36號',
        note: '我幫我媽預約，媽媽姓名王美花',
      }
    }
  },
}
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;

    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }

  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}
</style>

<style lang="scss" scoped>
.liff-reservation-form {
	&__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.card-header {
			border-radius: 10px 10px 0 0;
			border-bottom: 1px solid #E5E5EA;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
		}
	}
	&__textarea {
		border-radius: 10px;
		border: 1px solid #636366;
		color: #2C2C2E;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.2;
		padding: 16px 12px;
	}

	input {
		font-size: 14px;
		height: auto;
    line-height: 1.2;
		border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
	}
}
</style>
